import { couponActivationState, predefinedPeriods } from "./enums";

function periodSelected() {
  return !!this.predefined_period;
}

export default {
  title: "Участники",
  singularTitle: "Участник",
  icon: "mdi-account-box-multiple",
  serverOptions: true,
  sortBy: "last_activity_time",
  sortDesc: true,
  permissions: {
    update: true,
  },

  name() {
    return this.name || this.phone || this.text || this.id;
  },

  rowClass() {
    return this.is_banned ? "red lighten-5" : this.coupon ? null : "yellow lighten-5";
  },

  fields: [
    { label: "ID", id: "id", component: "primary" },
    { label: "Внешний ID", id: "external_id", disable: true },
    { label: "Мессенджер ID", id: "messenger_id", disable: true },
    { label: "Имя", id: "name", disable: true },
    { label: "Телефон", id: "phone", disable: true },

    {
      label: "Город",
      id: "region_id",
      component: "belongs-to",
      relation: "region",
      resourceName: "regions",
      disable: true,
      sortable: false,
    },

    { label: "Дата рождения", id: "birthdate", component: "date", disable: true },
    { label: "ИНН", id: "inn", disable: true, hideFromIndex: true },
    { label: "Должность", id: "job_title", disable: true },
    { label: "Кол-во Фото", id: "receipts_count", disable: true },
    { label: "Фото с кэшбэком", id: "receipts_with_cash_back_count", disable: true },

    {
      id: "coupon.activation_state",
      label: "Регистрация",
      component: "enum",
      options: couponActivationState,
      hideFromIndex: true,
      disable: true,
    },

    {
      label: "Купон",
      id: "coupon_id",
      component: "belongs-to",
      resourceName: "coupons",
      relation: "coupon",
      disable: true,
      sortable: false,
    },

    {
      label: "Партнер",
      id: "partner_id",
      component: "belongs-to",
      resourceName: "partners",
      relation: "coupon.partner",
      disable: true,
      sortable: false,
    },

    {
      label: "Рефер",
      id: "refer_id",
      component: "belongs-to",
      resourceName: "clients",
      relation: "refer",
      //disable: true,
      sortable: false,
      hideFromDetails: true,
    },

    {
      label: "Реферальный код",
      id: "referral_code",
      disable: true,
      sortable: false,
      hideFromIndex: true,
    },

    {
      label: "Реферальная цепочка",
      id: "refers",
      component: "custom-element",
      getter: function () {
        if (!(this.refers||[]).length) return { component: 'span', children: [ 'Корневой реферал' ] };

        return {
          component: 'ol',
          children: this.refers.map(c => ({
            component: 'li',
            children: [{
              component: 'router-link',
              data: {
                props: { to: '/clients/' + c.id },
              },
              children: [ c.name ],
            }]
          })),
        };
      },
    },

    { label: "Баланс", id: "balance", component: "numeric", disable: true },
    { label: "Зарегистрирован", id: "registered_at", component: "date", disable: true },
    { label: "Заблокирован", id: "is_banned", component: "boolean", default: false },
    { label: "Тестовый", id: "is_test", component: "boolean", default: false },
    { label: "Активность", id: "last_activity_time", component: "date", disable: true },
  ],

  relations: [
    {
      id: "receipts",
      resourceName: "receipts",
      foreignKey: "client_id",
      relation: "receipts",
    },

    {
      id: "orders",
      resourceName: "orders",
      foreignKey: "client_id",
      relation: "orders",
    },

    {
      id: "transactions",
      resourceName: "transactions",
      foreignKey: "target_id",
      morphKeyName: "target_type",
      morphKey: "client",
      relation: "transactions",
    },

    {
      id: 'prize',
      label: 'Избранные призы',
      resourceName: 'products',
      foreignKey: 'client_id',
      manyToMany: true,
      permissions: {
        create: false,
        update: false,
      },
    },

    {
      id: "clients",
      label: "Пригласил клиентов",
      resourceName: "clients",
      foreignKey: 'refer_id',
      localKey: 'referral_code',
    }
  ],

  views: {
    report: {
      title: "Отчет по участникам",
      url: "/clients/report",

      fields: [
        { label: "ID", id: "id", component: "primary" },
        { label: "Имя", id: "name", sortable: false },
        { label: "Телефон", id: "phone", sortable: false },

        {
          label: "Город",
          id: "region_id",
          component: "belongs-to",
          relation: "region",
          resourceName: "regions",
          disable: true,
        },

        {
          label: "Фото с кэшбэком",
          id: "receipts_with_cash_back_count",
          sortable: false,
        },

        { label: "Кэшбэк", id: "cash_back_sum", sortable: false },
        { label: "Выплаты", id: "payouts_sum", sortable: false },
        { label: "Зачислений", id: "credit_sum", sortable: false },
        { label: "Списаний", id: "debit_sum", sortable: false },

        {
          label: "Баланс на начало периода",
          id: "start_balance",
          sortable: false,
        },

        { label: "Баланс на конец периода", id: "end_balance", sortable: false },
      ],

      filters: [
        {
          id: "predefined_period",
          label: "Период",
          component: "enum",
          options: predefinedPeriods,
          clearable: true,
          default: "week",
        },

        {
          id: "from",
          label: "Дата от",
          component: "date",
          disable: periodSelected,
        },

        {
          id: "to",
          label: "Дата до",
          component: "date",
          endOfDay: true,
          disable: periodSelected,
        },
      ],
    },
  },
};
